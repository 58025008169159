<template>
    <div>
        <div v-if="tempObject.id">
                                            <v-toolbar>
                <v-btn  v-if="isMobile==true"
                        fab small depressed
                        @click='$emit("close")'
                        >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-toolbar-title>           

                </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn icon @click='$emit("update:editMod",!editMod)' v-if="!editMod && tempObject.isAdmin==true"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click='deleteModal=true' v-if="!editMod && tempObject.isAdmin==true" color="error"><v-icon>mdi-delete</v-icon></v-btn>
                <v-btn icon @click='$emit("update:editMod",!editMod)' v-if="editMod && tempObject.isAdmin==true" color="error"><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn icon @click='objectSave()' v-if="editMod && tempObject!=object && tempObject.isAdmin==true" color="success"><v-icon>mdi-content-save</v-icon></v-btn>
            </v-toolbar>
 
            <div :style='isMobile?"position: fixed; top:60px; right:10px; z-index:10;":"position: fixed; top:60px; right:70px; z-index:10;"'>
            <v-btn fab  class="mx-1" @click='uploadSlideModal()' small v-if="tempObject.isAdmin==true" color="primary"><v-icon>mdi-upload</v-icon></v-btn>
            <v-btn fab  class="mx-1" @click.prevent.stop="fileTemp=tempObject.images[curSlide]; fileRenameModal=true" small v-if="tempObject.images.length>0 && tempObject.isAdmin==true" color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn fab  class="mx-1" @click.prevent.stop="fileTemp=tempObject.images[curSlide]; fileDeleteModal=true"  small v-if="tempObject.images.length>0 && tempObject.isAdmin==true" color="error"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
            <v-carousel v-if="tempObject.id && tempObject.images.length>0"
                        :continuous="true"
                        :cycle="true"
                        :show-arrows="false"
                        v-model="curSlide"
                        hide-delimiter-background

                        height="200"
                        >
                <v-carousel-item
                    style='cursor:pointer;'
                    @click="showFile(tempObject.images[curSlide])"
                    v-for="(slide, i) in tempObject.images"
                    :key="i"
                    :src="slide.src"
                    >
                </v-carousel-item>
            </v-carousel>
            <v-img v-if="tempObject.id && tempObject.images.length==0" src="https://atgnn.ru/wp-content/uploads/2019/08/nophoto.jpg" height="200"/>
        </div>
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <v-img :src="getObjectIcon(object.type)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-textarea dense hide-details="auto" class="pa-0" label="Название" v-if='editMod==true' v-model='tempObject.name' auto-grow :rows="1" row-height="20" :background-color="tempObject.name!=object.name?'amber lighten-4':''"></v-textarea>
                    <span v-else>{{object.name}} <v-btn v-if="tempObject.id" fab @click='$emit("showPath",object)' small color="primary"><v-icon>mdi-map-marker-path</v-icon></v-btn>
                        <v-btn v-if="tempObject.id" fab @click='carsSend(object)' small color="primary"><v-icon>mdi-alert</v-icon></v-btn></span>
                </v-list-item-content>
                <v-list-item-action v-if="!tempObject.id">
                    <v-btn icon @click='$emit("close")'><v-icon color='error'>mdi-close</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-item style='min-height: 0px;'>
                <h5 v-if="!editMod && stations.length>0">{{stations.find(el=>el.id==tempObject.stationId).name}}</h5>
                <v-select v-else-if="stations.length>0"
                          item-value="id"
                          item-text="name"
                          :items="stations"
                          v-model="tempObject.stationId"
                          label="Пожарная часть"
                          ></v-select>
            </v-list-item>
            <v-list-item style='min-height: 0px;'>
                <h5 v-if="!editMod">{{getObjectType(object.type)}}</h5>
                <v-select v-else
                          :items="types"
                          v-model="tempObject.type"
                          label="Тип объекта"
                          ></v-select>
            </v-list-item>
            <v-list-item style='min-height: 0px;'>
                <h5 v-if="!editMod">{{getObjectKind(object.kind)}}</h5>
                <v-select v-else
                          :items="kinds"
                          v-model="tempObject.kind"
                          label="Вид объекта"
                          ></v-select>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <v-list-item-content><v-textarea :error='errors.text' :error-messages="errors.text?errors.text[0]:''" label="Описание" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.text' auto-grow :rows="1" row-height="20" :background-color="tempObject.text!=object.text?'amber lighten-4':''"></v-textarea><span v-else>{{object.text}}</span></v-list-item-content>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3">Телефон:</b>  <v-text-field :error='errors.phone' :error-messages="errors.phone?errors.phone[0]:''" height="20" class="pa-0" dense hide-details="auto" v-if='editMod==true' v-model='tempObject.phone' :background-color="tempObject.phone!=object.phone?'amber lighten-4':''"></v-text-field><span v-else>{{object.phone}}</span>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3" style="white-space: nowrap">Количество людей:</b> <v-text-field :error='errors.peopleNumber' :error-messages="errors.peopleNumber?errors.peopleNumber[0]:''"  dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.peopleNumber' :background-color="tempObject.peopleNumber!=object.peopleNumber?'amber lighten-4':''"></v-text-field><span v-else>{{object.peopleNumber}}</span>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3">Ранг пожара:</b> <v-text-field :error='errors.rank' :error-messages="errors.rank?errors.rank[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.rank' :background-color="tempObject.rank!=object.rank?'amber lighten-4':''"></v-text-field><span v-else>{{object.rank}}</span>
            </v-list-item>
        </v-list>
        <v-tabs  :show-arrows="false"     fixed-tabs
                 v-model="tab"
                 >
            <v-tab>Хар-ка</v-tab>
            <v-tab>СПЗ</v-tab>
            <v-tab v-if="tempObject.id">Схемы</v-tab>

        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-list>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3">Степень огнестойкости</b>  <v-text-field :error='errors.fireResistance' :error-messages="errors.fireResistance?errors.fireResistance[0]:''" height="20" class="pa-0" dense hide-details="auto" v-if='editMod==true' v-model='tempObject.fireResistance' :background-color="tempObject.fireResistance!=object.fireResistance?'amber lighten-4':''"></v-text-field><span v-else>{{object.fireResistance}}</span>
                            </v-list-item>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3" style="white-space: nowrap">Высота:</b> <v-text-field :error='errors.height' :error-messages="errors.height?errors.height[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.height' :background-color="tempObject.height!=object.height?'amber lighten-4':''"></v-text-field><span v-else>{{object.height}}</span>

                            </v-list-item>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3" style="white-space: nowrap">Этажи:</b> <v-text-field :error='errors.floors' :error-messages="errors.floors?errors.floors[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.floors' :background-color="tempObject.floors!=object.floors?'amber lighten-4':''"></v-text-field><span v-else>{{object.floors}}</span>
                            </v-list-item>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3" style="white-space: nowrap">Входы:</b> <v-text-field :error='errors.enters' :error-messages="errors.enters?errors.enters[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.enters' :background-color="tempObject.enters!=object.enters?'amber lighten-4':''"></v-text-field><span v-else>{{object.enters}}</span>
                            </v-list-item>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3" style="white-space: nowrap">Кровля:</b> <v-text-field :error='errors.roof' :error-messages="errors.roof?errors.roof[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.roof' :background-color="tempObject.roof!=object.roof?'amber lighten-4':''"></v-text-field><span v-else>{{object.roof}}</span>
                            </v-list-item>
                            <v-list-item style='min-height: 0px;' class="py-1">
                                <b class="mr-3" style="white-space: nowrap">Подвал:</b> <v-text-field :error='errors.basement' :error-messages="errors.basement?errors.basement[0]:''" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.basement' :background-color="tempObject.basement!=object.basement?'amber lighten-4':''"></v-text-field><span v-else>{{object.basement}}</span>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-list v-if="!editMod">
                            <v-list-item  two-line style='min-height: 0px;' class="py-1" v-if="object.systems && object.systems.aps && object.systems.aps.value==1">
                                <v-list-item-content>
                                    <v-list-item-title>АПС </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="object.systems.aps.desc">{{object.systems.aps.desc}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1" v-if="object.systems && object.systems.aupt && object.systems.aupt.value==1">
                                <v-list-item-content>
                                    <v-list-item-title>АУПТ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="object.systems.aupt.desc">{{object.systems.aupt.desc}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1" v-if="object.systems && object.systems.soue && object.systems.soue.value==1">
                                <v-list-item-content>
                                    <v-list-item-title>СОУЭ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="object.systems.soue.desc">{{object.systems.soue.desc}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1" v-if="object.systems && object.systems.pdz && object.systems.pdz.value==1">
                                <v-list-item-content>
                                    <v-list-item-title>ПДЗ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="object.systems.pdz.desc">{{object.systems.pdz.desc}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1" v-if="object.systems && object.systems.vpv && object.systems.vpv.value==1">
                                <v-list-item-content>
                                    <v-list-item-title>ВПВ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="object.systems.vpv.desc">{{object.systems.vpv.desc}}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-list v-if="editMod && tempObject.systems">
                            <v-list-item  two-line style='min-height: 0px;' class="py-1">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="tempObject.systems.aps.value"
                                        ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>АПС </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-textarea outlined class="pa-0"  hide-details="auto" auto-grow :rows="1" row-height="20" v-if='tempObject.systems.aps.value' v-model='tempObject.systems.aps.desc'></v-textarea>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="tempObject.systems.aupt.value"
                                        ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>АУПТ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-textarea outlined class="pa-0"  hide-details="auto" auto-grow :rows="1" row-height="20" v-if='tempObject.systems.aupt.value' v-model='tempObject.systems.aupt.desc'></v-textarea>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="tempObject.systems.soue.value"
                                        ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>СОУЭ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-textarea outlined class="pa-0"  hide-details="auto" auto-grow :rows="1" row-height="20" v-if='tempObject.systems.soue.value' v-model='tempObject.systems.soue.desc'></v-textarea>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="tempObject.systems.pdz.value"
                                        ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content style="height: 100%">
                                    <v-list-item-title>ПДЗ </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-textarea outlined class="pa-0"  hide-details="auto" auto-grow :rows="1" row-height="20" v-if='tempObject.systems.pdz.value' v-model='tempObject.systems.pdz.desc'></v-textarea>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  two-line style='min-height: 0px;' class="py-1">
                                <v-list-item-action>
                                    <v-checkbox
                                        v-model="tempObject.systems.vpv.value"
                                        ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>ВПВ </v-list-item-title>
                                    <v-textarea outlined class="pa-0"  hide-details="auto" auto-grow :rows="1" row-height="20" v-if='tempObject.systems.vpv.value' v-model='tempObject.systems.vpv.desc'></v-textarea>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-list>
                            <v-list-item-group
                                color="primary">
                                <v-tooltip left v-for="(file,index) in object.schemes" :key="index">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item
                                            v-if="!mobile"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="showFile(file)"
                                            :disabled="fileView==index"
                                            :alt="file.name"
                                            >
                                            <v-list-item-icon>
                                                <v-icon v-if="file.ext=='pdf'"
                                                        class="red--text"
                                                        >far fa-file-pdf</v-icon>
                                                <v-icon v-if="file.ext=='docx'"
                                                        class="blue--text"
                                                        >far fa-file-word</v-icon>
                                                <v-icon v-if="file.ext!='docx' && file.ext!='pdf'"
                                                        style="color:#d86043"
                                                        >far fa-file-image</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="file.name"></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action v-if="tempObject.isAdmin">
                                                <div>
                                                    <v-btn icon @click.prevent.stop="fileTemp=JSON.parse(JSON.stringify(file)); fileRenameModal=true">
                                                        <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn icon @click.prevent.stop="fileTemp=file; fileDeleteModal=true">
                                                        <v-icon color="error lighten-1">mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item v-else 
                                                     v-bind="attrs"
                                                     v-on="on"


                                                     :alt="file.name"
                                                     >
                                            <a :href="'https://drive.google.com/viewerng/viewer?embedded=true&url=' +file.src">{{file.name}}</a>
                                        </v-list-item>
                                    </template>
                                    <span>{{file.name}}</span>
                                </v-tooltip>
                                <v-list-item v-if="tempObject.isAdmin"
                                             @click="uploadSchemeModal()"
                                             >
                                    <v-list-item-icon>
                                        <v-icon color='primary'
                                                >mdi-plus-thick</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Добавить схему</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>



                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-btn  @click='objectSave()'  class="ma-2" style="float:right" v-if="!tempObject.id" color="success">Сохранить<v-icon>mdi-content-save</v-icon></v-btn>

        <v-dialog v-model='uploadModal' max-width="400">
            <v-card>
                <v-card-title class="headline" v-if="uploadType=='image'">Добавить изображение</v-card-title>
                <v-card-title class="headline" v-else>Добавить схему</v-card-title>
                <v-card-text>
                    <v-overlay
                        absolute
                        :value="uploading"
                        >
                    </v-overlay> 
                    <v-list-item-subtitle class="text-wrap">
                        Прикрепите документ и нажмите загрузить
                    </v-list-item-subtitle>
                    <div class="mt-4">
                        <v-file-input show-size v-model="newFile.file" label="Изображение" accept=".jpeg, .jpg, .png" v-if="uploadType=='image'"></v-file-input>
                        <v-file-input show-size v-model="newFile.file" label="Файл" accept=".pdf, .docx, .jpeg, .jpg, .png, .vsd, .vsdx" v-else></v-file-input>
                        <v-text-field v-model="newFile.name" label="Название"></v-text-field>
                    </div>
                    <div v-if="uploadType=='image'"><v-btn color="primary" v-if="!uploading" @click="uploadSlide()">Загрузить</v-btn></div>
                    <div v-else><v-btn color="primary" v-if="!uploading" @click="uploadScheme()">Загрузить</v-btn></div>
                    <v-progress-linear style="width:100%"
                                       v-if="uploading"
                                       v-model="uploadPercentage"
                                       height="25"
                                       >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>
        <v-dialog v-model="fileDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление документа невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="fileDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fileRenameModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Переименовать</v-card-title>
                <v-card-text><v-text-field v-model="fileTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileRenameModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="fileRename()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление объекта невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="deleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="objectDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Example",
        components: {

        },
        props: {
            isMobile: Boolean,
            mobile: Boolean,
            docView: Object,
            editMod: Boolean,
            object: Object,
            stations: Array
        },
        watch: {
            'object'() {
                this.cloneObject()
            }
        },
        editedAreas() {

            var stations = this.stations.filter(el => el.isAdmin != true)
            if (!stations) {
                stations = []
            }
            return stations;

        },
        data() {
            return {
                errors: {},
                deleteModal: false,
                fileTemp: {},
                fileDeleteModal: false,
                fileRenameModal: false,
                fileView: null,
                uploadModal: false,
                curSlide: 0,
                uploading: false,
                uploadType: null,
                uploadPercentage: 0,
                newFile: {},
                kinds: [
                    {value: 'House', text: 'Жилое здание'},
                    {value: 'Child', text: 'Детское учреждение'},
                    {value: 'School', text: 'Школьное учреждение'},
                    {value: 'Medical', text: 'Медицинское учреждение'},
                    {value: 'Prom', text: 'Промышленный объект'},
                    {value: 'Admin', text: 'Административное учреждение'},
                    {value: 'Religion', text: 'Религиозный объект'},
                    {value: 'Sport', text: 'Спортивный объект'},
                    {value: 'Tourist', text: 'Объект туризма'},
                    {value: 'Trade', text: 'Торговый объект'}]
                ,
                types: [
                    {value: 'POO', text: 'Потенциально-опасный объект'},
                    {value: 'KVO', text: 'Критически важный объект'},
                    {value: 'SIO', text: 'Социально-значимый объект'},
                    {value: 'CIO', text: 'Объект культурного наследия'},
                    {value: 'NSO', text: 'Объект с ночным пребыванием '},
                    {value: 'MPL', text: 'Объект с массовым пребыванием людей'}
                ],
                tab: 0,
                tempObject: {}
            };
        },
        created() {
            this.cloneObject()
        },
        methods: {
            carsSend(object) {
                this.$http.get('area/track-send?objectId=' + object.id).then(() => {
                    alert('Успешно')
                })
            },
            objectDelete() {
                this.$http.post('stations/object-delete?id=' + this.tempObject.id).then(() => {
                    let key = this.stations.findIndex(el => el.id == this.tempObject.stationId)
                    let objectKey = this.stations[key].areaObjects.findIndex(el => el.id == this.tempObject.id)
                    this.stations[key].areaObjects.splice(objectKey, 1)
                    this.deleteModal = false
                    this.$emit("close")
                })
            },
            showFile(file) {
                this.$emit('update:docView', file)
            },
            fileDelete() {
                this.$http.get('stations/file-delete',
                        {
                            params: {
                                id: this.fileTemp.id
                            }
                        }).then(() => {
                    if (this.fileTemp.type == "image") {
                        let index = this.tempObject.images.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.images.splice(index, 1)
                        this.object.images.splice(index, 1)
                    } else {
                        let index = this.tempObject.schemes.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.schemes.splice(index, 1)
                        this.object.schemes.splice(index, 1)
                    }
                    this.fileTemp = {}
                    this.fileDeleteModal = false
                })
            },
            fileRename() {
                this.$http.get('stations/file-rename',
                        {
                            params: {
                                id: this.fileTemp.id,
                                name: this.fileTemp.name,
                            }
                        }).then(() => {
                    if (this.fileTemp.type == "image") {
                        let index = this.tempObject.images.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.images[index].name = this.fileTemp.name
                        this.object.images[index].name = this.fileTemp.name
                    } else {
                        let index = this.tempObject.schemes.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.schemes[index].name = this.fileTemp.name
                        this.object.schemes[index].name = this.fileTemp.name
                    }
                    this.fileTemp = {}
                    this.fileRenameModal = false
                })

            },
            uploadSlideModal() {
                this.uploadType = 'image'
                this.uploadModal = true
            },
            uploadSchemeModal() {
                this.uploadType = 'scheme'
                this.uploadModal = true
            },
            uploadSlide() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newFile.name)
                formData.append("file", this.newFile.file)
                this.$http.post('stations/file-upload?objectId=' + this.tempObject.id + '&type=' + this.uploadType,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.newFile = {}
                    if (!this.object.images) {
                        this.$set(this.object, 'images', [])
                        this.$set(this.tempObject, 'images', [])
                    }
                    this.tempObject.images.push(response.data)
                    this.object.images.push(response.data)
                    this.uploadModal = false
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            uploadScheme() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newFile.name)
                formData.append("file", this.newFile.file)
                this.$http.post('stations/file-upload?objectId=' + this.tempObject.id + '&type=' + this.uploadType,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.newFile = {}
                    if (!this.object.schemes) {
                        this.$set(this.tempObject, 'schemes', [])
                        this.$set(this.object, 'schemes', [])
                    }
                    this.tempObject.schemes.push(response.data)
                    this.object.schemes.push(response.data)
                    this.uploadModal = false
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            objectSave() {
                if (this.tempObject.id) {
                    this.$http.post('stations/object-update?id=' + this.tempObject.id,
                            this.tempObject).then(() => {
                        this.$emit('update:object', this.tempObject)
                        let key = this.stations.findIndex(el => el.id == this.tempObject.stationId)
                        let objectKey = this.stations[key].areaObjects.findIndex(el => el.id == this.tempObject.id)
                        this.$set(this.stations[key].areaObjects, objectKey, this.tempObject)
                        this.$emit('update:editMod', false)
                    }).catch(err => {
                        this.errors = err
                    })
                } else {
                    this.$http.post('stations/object-create',
                            this.tempObject).then(response => {
                        let object = this.tempObject
                        object.id = response.data.id
                        object.images = []
                        object.schemes = []
                        let key = this.stations.findIndex(el => el.id == this.tempObject.stationId)
                        this.stations[key].areaObjects.push(object)
                        this.cloneObject()
                        this.$emit('update:editMod', false)
                        this.$emit('close')
                    }).catch(err => {
                        this.errors = err.response.data
                    })
                }


            },
            cloneObject() {
                this.tempObject = JSON.parse(JSON.stringify(this.object))
                if (!this.tempObject.systems) {
                    this.$set(this.tempObject, 'systems', {})
                }
                if (!this.tempObject.systems.aps) {
                    this.$set(this.tempObject.systems, 'aps', {value: false, desc: ''})
                }
                if (!this.tempObject.systems.aupt) {
                    this.$set(this.tempObject.systems, 'aupt', {value: false, desc: ''})
                }
                if (!this.tempObject.systems.soue) {
                    this.$set(this.tempObject.systems, 'soue', {value: false, desc: ''})
                }
                if (!this.tempObject.systems.pdz) {
                    this.$set(this.tempObject.systems, 'pdz', {value: false, desc: ''})
                }
                if (!this.tempObject.systems.vpv) {
                    this.$set(this.tempObject.systems, 'vpv', {value: false, desc: ''})
                }
            },
            getObjectType(type) {
                switch (type) {
                    case 'POO':
                        return 'Потенциально-опасный объект'
                    case 'KVO':
                        return 'Критически важный объект'
                    case 'SIO':
                        return 'Социально-значимый объект'
                    case 'CIO':
                        return 'Объект культурного наследия'
                    case 'NSO':
                        return 'Объект с ночным пребыванием '
                    case 'MPL':
                        return 'Объект с массовым пребыванием людей'
                }
            },
            getObjectIcon(type) {
                switch (type) {
                    case 'POO':
                        return '/map/poo.png'
                    case 'KVO':
                        return '/map/kvo.png'
                    case 'SIO':
                        return '/map/social.png'
                    case 'CIO':
                        return '/map/culture.png'
                    case 'NSO':
                        return '/map/night.png'
                    case 'MPL':
                        return '/map/mass.png'
                }
            },
            getObjectKind(kind) {
                switch (kind) {
                    case 'House':
                        return 'Жилое здание'
                    case 'Child':
                        return 'Детское учреждение'
                    case 'School':
                        return 'Школьное учреждение'
                    case 'Medical':
                        return 'Медицинское учреждение'
                    case 'Prom':
                        return 'Промышленный объект'
                    case 'Admin':
                        return 'Административное учреждение'
                    case 'Religion':
                        return 'Религиозный объект'
                    case 'Sport':
                        return 'Спортивный объект'
                    case 'Tourist':
                        return 'Объект туризма'
                    case 'Trade':
                        return 'Торговый объект'
                }
            },

        }
    };
</script>
